/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Container} from "@chakra-ui/react";
import {YouTube, Gist} from "mdx-embed";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3",
    p: "p",
    em: "em",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Express Best Practices Journal"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "development"), "\n", React.createElement(_components.li, null, "testing"), "\n", React.createElement(_components.li, null, "staging"), "\n", React.createElement(_components.li, null, "production"), "\n"), "\n", React.createElement(_components.h2, null, "Common"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "logging"), "\n", React.createElement(_components.li, null, "auth"), "\n", React.createElement(_components.li, null, "architecture"), "\n"), "\n", React.createElement(_components.h2, null, "Development"), "\n", React.createElement(_components.h3, null, "Logging"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Why is logging useful?")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.digitalocean.com/community/tutorials/nodejs-getting-started-morgan"
  }, "node logging with morgan - Digital Ocean")), "\n", React.createElement(_components.li, null, "[node logging with morgan and winston - Digital Ocean]"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=cWi7TAyVoZo"
  }, "logger in Node js express - Programming with Basar")), "\n"), "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "cWi7TAyVoZo"
  })), "\n", React.createElement(_components.h2, null, "Testing"), "\n", React.createElement(_components.h2, null, "Staging"), "\n", React.createElement(_components.h2, null, "Production"), "\n", React.createElement(_components.h2, null, "Reference"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
